:root {
	--bg: #f1f2f2;
	--red-title: #c32126;
	--gray-500: #3a393a;
	--gray-400: #d8d7d8;
	--gray-300: #99753c;
	--red-500: #d72229;
}

.container {
	width: 1220px;
	margin: 0 auto;
	padding: 0 15px;
}
@media screen and (min-width: 1536px) {
	.container {
		max-width: 1280px !important;
	}
}
/* @media screen and (min-width: 1200px) {
	.container {
		max-width: 1170px;
	}
} */
/* @media screen and (min-width: 992px) {
	.container {
		width: 970px;
	}
} */

/* @media screen and (min-width: 768px) {
	.container {
		width: 750px;
	}
} */
/* Data Banner */
.wrapper {
	width: 100%;
	background: var(--bg);
	min-height: 60vh;
	height: 100%;
	padding-bottom: 32px;
}

/* Data Sub Banner */
.sub_wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 16px;
	margin-top: 8px;
}
/* Data Infor */
.infor_wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	justify-content: center;
	margin-top: 40px;
}
.infor_title {
	margin: 0 auto;
	width: 100%;
	text-align: center;
	.h1 {
		color: var(--red-title);
		font-size: 50px;
		font-weight: 700;
		margin: 0;
	}
	.p {
		margin: 10px 0;
		color: var(--gray-500);
		font-size: 16px;
	}
}
.infor_brand {
	display: flex;
}
.infor_brand__slide {
	width: 50%;

	.img {
		height: 100%;
	}
}
.infor_grid {
	width: 50%;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));

	.a {
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 1px solid var(--gray-400);
		border-bottom: 1px solid var(--gray-400);
	}
	a:nth-child(1),
	a:nth-child(4),
	a:nth-child(4) {
		border-left: 1px solid var(--gray-400);
	}

	a:nth-child(1),
	a:nth-child(2),
	a:nth-child(3) {
		border-top: 1px solid var(--gray-400);
	}
}

/* Data Product */
.product_wrapper {
	width: 100%;
	background: var(--bgcolor);
	padding: 16px 0;
	min-height: 636px;
	margin: 16px auto auto 0;
	.countdown_wrapper {
		margin-left: 10%;
		.h2 {
			color: red;
			font-size: 24px;
			margin: 0 !important;
		}
	}
	.countdown_count {
		font-size: 16px;
		font-weight: 600;
	}
}
.product_flex {
	display: flex;
	width: 100%;
}
.product_left {
	min-width: 416px;
	.picture {
		height: 100%;
	}
	.img {
		height: 100%;
	}
}

.product_right {
	display: flex;
	flex-wrap: wrap;
}

.productcard {
	position: relative;
	width: 25%;
	background-color: white;
	padding: 16px;
	border-left: 1px solid var(--gray-400);
	border-bottom: 1px solid var(--gray-400);
}

.product_image {
	width: 138px;
	height: 138px;
}

.product_des {
	text-align: center;
	margin-top: 4px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	.brand {
		font-weight: 600;
		color: var(--gray-500);
		font-size: 12px;
	}
	.fullname {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		color: var(--gray-500);
		font-size: 12px;
		min-height: 27.5px;
	}
	.prices {
		display: inline-flex;
		justify-content: center;
		gap: 8px;
		align-items: center;
	}
	.action {
		width: 100%;
		position: absolute;
		bottom: 16px;
		left: 0;
		display: flex;
		gap: 4px;
		justify-content: center;

		button {
			font-size: 8px;
			border-radius: 4px;
			border: 1px solid var(--gray-300);
			word-break: keep-all;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
			width: 40%;
			height: 20px;
		}
		button:nth-child(1) {
			background: white;
			color: (var(--gray-300));
		}
		button:nth-child(2) {
			background: var(--gray-300);
			color: white;
		}
	}
}
.price_final {
	color: var(--red-500);
	font-weight: 700;
}
.price_compare {
	text-decoration-line: line-through;
}
/* Data Brand */
.brand_wrapper {
	width: 100%;
	background-color: white;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	display: flex;
	justify-content: center;

	.a {
		width: calc(100% / 10);
		height: 80px;
		max-height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.img {
		vertical-align: middle;
		width: 100%;
		height: 100%;
		object-fit: contain;
		max-width: 100%;
	}
}
/* Data Support */
.support_wrapper {
	width: 100%;
	height: 100%;
	margin: 32px 0;
}
