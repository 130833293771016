#features_nteam {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

@media screen and (min-width: 769px) {
  body:has(.coupon_list) #features_nteam:first {
    background-color: #f7f7f7;
  }
  body:has(.coupon_wrapper) #features_nteam:first {
    background-color: #f7f7f7;
  }
}
body {
  min-height: 60vh;
}
body:has(.coupon_wrapper) .auth_header {
  display: none;
}

body:has(.coupon_wrapper) .auth_bottom {
  display: none;
}

body:has(.scrollableDiv_hide_operation) .ant-image-preview-operations-wrapper {
  display: none !important;
}

@media (min-width: 1200px) {
  #features_nteam {
    width: 100%;
  }
  /* @media (min-width: 992px) {
    .features_nteam {
      width: 970px;
    }
  } */
}

/*  */
.swiper {
  height: 100%;
  width: 100%;
  overflow-x: unset;
}
#space_block {
  display: block;
  width: 100%;
}
#slide_react .swiper-button-next {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: transparent !important;
  border-radius: 100% !important;
  cursor: pointer !important;
  background: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
#slide_react .swiper-button-prev {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: transparent !important;
  border-radius: 100% !important;
  cursor: pointer !important;
  background: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
@media screen and (max-width: 768px) {
  #slide_react .swiper-button-prev {
    display: none !important;
  }
  #slide_react .swiper-button-next {
    display: none !important;
  }
}
#slide_react .swiper-button-next {
  transform: translateY(1%) !important ;
}
#slide_react .swiper-button-prev {
  transform: translateY(1%) !important ;
}

#slide_react .slide-review-detail::after {
  /* color: #3a393a !important;
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important; */
  display: none !important;
}
#slide_react .swiper-button-prev::after {
  font-size: 14pt !important;
  color: #3a393a !important;
  display: none !important;
}
#slide_react .swiper-button-next::after {
  font-size: 14pt !important;
  color: #3a393a !important;
  display: none !important;
}
.infinite-scroll-component {
  overflow-x: hidden !important;
}
#webkit-scroll::-webkit-scrollbar {
  width: 5px !important;
  background-color: #e5e7eb !important;
}

#webkit-scroll .ant-list-item:first-child {
  padding: 0 0 12px 0 !important;
}
#webkit-scroll .ant-list-item:last-child {
  /* padding: 12px 0 0 0 !important; */
  /* border-block-end: none; */
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 5px !important;
  background-color: #e5e7eb !important;
  display: none !important;
}
.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: #65676b !important;
}
#webkit-scroll::-webkit-scrollbar-thumb {
  background-color: #65676b !important;
}
#webkit-scroll-hide::-webkit-scrollbar {
  display: none !important;
}
#webkit-scroll-hide .ant-list-item:first-child {
  /* padding-bottom: 100px !important; */
  /* padding: 0 0 12px 0 !important; */
  /* padding-bottom: 0 !important; */
}
#webkit-scroll-hide .ant-list-item:last-child {
  /* padding: 12px 0 0 0 !important; */
  /* padding-bottom: 100px !important; */
  /* border-block-end: none; */
}

.ant-collapse-content-box {
  padding: 0 !important;
}
#scrollableDiv .ant-list-item {
  margin-block-end: 8px !important;
}
.hide-tree-node .ant-tree-node-content-wrapper-open {
  display: none !important;
}
.hide-tree-node {
  min-width: 90px;
}
#slide_wrapper_wa .swiper-slide {
  width: auto !important;
}
.inline {
  display: inline-block !important;
}
#nperp-features .swiper-scrollbar {
  display: none !important;
}

/* .ant-modal-mask {
  z-index: 10000000000000000000000000000 !important;
} */
