.wrapper {
	display: inline-flex;
}
.countRate {
	font-size: 12px !important;
	line-height: 1.5 !important;
	color: #3a393a !important;
	margin-left: 2px;
}
.listRate {
	display: inline-flex;
	gap: 4px;
}
@media screen and (max-width: 768px) {
	.countRate_title {
		display: none;
	}
}
