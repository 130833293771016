.response_col_to_row {
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
}
.response_col_to_row span {
  width: fit-content !important;
  display: inline !important;
}
@media screen and (max-width: 768px) {
  .response_col_to_row {
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  .response_col_to_row h3 {
    font-size: 16px !important;
    font-weight: 700 !important;
    margin: 0 !important;
  }

  h3#h3_wrapper {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
}

/* title H3 response  */
