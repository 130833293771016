$primaryColor: #3a393a;
$secondaryColor: #d8d7d8;
$thirdColor: #898889;

$primaryBorder: #3a393a;
$secondaryBorder: #d8d7d8;

$mainColor: #d72229;
$linkColor: #004b8f;

$primaryBackground: #ffffff;
$secondaryBackground: #d8d7d8;
$secondaryBackground_1: #f7f7f7;

$borderPrimary: #898889;
/////////////
$fontSizeHeading: 18px;
$fontSizeDefault: 12px;
/////////////

$host_asset: "https://cross.namefragrance.vn/imgs/";

/////////////
#features_nteam {
  * {
    box-sizing: border-box;
  }

  img,
  picture,
  svg,
  video {
    max-width: 100%;
  }
  input,
  select,
  textarea {
    outline: none;
  }
  button {
    cursor: pointer;
    outline: none;
  }

  label,
  label {
    font-weight: unset;
  }

  /////////////////////
  /// reset css antd
}
.ant-picker-cell-inner {
  text-align: center !important;
}

.nperp_container {
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    width: 750px !important;
  }
  @media screen and (min-width: 992px) {
    width: 970px !important;
  }
  @media screen and (min-width: 1200px) {
    width: 1170px !important;
  }
}

//class global
.text_strong {
  font-weight: 600;
}
////////////////////////////////
//components
///MainInput.tsx
.main_input_wrapper {
  border-bottom: 1px solid $borderPrimary;
  position: relative;
  text-overflow: ellipsis;

  &_label {
    position: absolute;
    inset: 0;
    font-size: $fontSizeDefault;
    color: $thirdColor;
    transition: all 0.2s linear;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100% !important;
    min-width: 70%;
    z-index: 9;
  }
  .main_input {
    width: 100%;
    border: none;
    position: relative;
    z-index: 10;
    padding: 0;
    background-color: transparent;
    &::placeholder {
      visibility: hidden;
    }
    &:not(:placeholder-shown) ~ .main_input_wrapper_label {
      top: -14px;
      font-size: 10px;
    }
    &:focus ~ .main_input_wrapper_label {
      top: -14px;
      font-size: 10px;
    }
  }
}
//MainDatePicker.tsx
.main_datepicker_wrapper {
  border-bottom: 1px solid $borderPrimary;
  position: relative;
  text-overflow: ellipsis;
  input {
    position: relative;
    z-index: 2;
  }
  &_label {
    position: absolute;
    inset: 0;
    font-size: $fontSizeDefault;
    color: $thirdColor;
    transition: all 0.2s linear;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;
    z-index: 1;
  }
  &_label_active {
    top: -14px;
    font-size: 10px !important;
  }
  .main_date_picker {
    input::placeholder {
      visibility: hidden;
    }
    &_active {
      input::placeholder {
        visibility: visible;
      }
    }
  }
  .ant-picker {
    padding: 0 !important;
  }
}

// MainButton.tsx
.main_button_wrapper {
  &_button {
    height: 32px;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    @media screen and (min-width: 769px) {
      width: 293px;
      max-width: 293px;
    }
  }
  .button_dark {
    color: #fff !important;
    background-color: $primaryBorder !important;
  }
  .button_light {
    border: 1px solid $primaryBorder !important;
    background-color: $primaryBackground !important;
  }
  .button_main {
    background-color: $mainColor !important;
    color: #fff !important;
    border: none !important;
  }
}

// CustomerMenu.tsx
.customer_menu_wrapper {
  .customer_nav {
    &_account {
      display: flex;
      height: 40px;
      background-color: $secondaryBackground_1;
      align-items: center;
      padding: 0 16px 0 12px;
      &_title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
      }
      ._logout {
        background-image: url($host_asset + "loyaltyIcon/loyalty-logout_icon.png");
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      &_arrow {
        flex: 1;
        height: 24px;
        width: 24px;
        ._arrow_down {
          background-repeat: no-repeat;
          background-image: url($host_asset + "loyaltyIcon/loyalty-expand-down_icon.png");
          height: 24px;
          width: 24px;
          float: right;
        }
        ._arrow_up {
          background-repeat: no-repeat;
          background-image: url($host_asset + "loyaltyIcon/loyalty-expand-up_icon.png");
          height: 24px;
          width: 24px;
          float: right;
        }
      }
    }
    // List menu
    .active_expand {
      max-height: 50vh;
    }
    &_list {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow: hidden;
      padding: 0 16px;
      max-height: 0;
      -webkit-transition: max-height 0.5s;
      -moz-transition: max-height 0.5s;
      -ms-transition: max-height 0.5s;
      -o-transition: max-height 0.5s;
      transition: max-height 0.5s;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          text-decoration: none;
          color: $primaryColor;
          position: relative;
        }
        a.active {
          color: $primaryColor;
          font-weight: 700;
        }
        .link_arrow {
          width: 24px;
          height: 24px;

          background-image: url($host_asset + "loyaltyIcon/loyalty-expand-right_icon.png");
          @media screen and (max-width: 768px) {
            display: inline-block;
          }
        }
      }
    }
  }
}

//NTRadioGroup
.nt_radio_group {
  .ant-checkbox-wrapper {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
}
//MainTextarea
.main_textarea {
  .ant-input-textarea-affix-wrapper.ant-input-affix-wrapper {
    box-shadow: none !important;
    border-radius: 4px;
    border: 1px solid $secondaryBackground !important;
    background: $primaryBackground !important;
  }
  .ant-input-textarea-show-count .ant-input-data-count {
    transform: translateY(-30px);
    color: #898989;
    font-size: 12px;
    font-weight: 400;
  }
  .ant-input-textarea-show-count > .ant-input {
    height: 78px;
    padding: 12px 16px 0;
    margin-bottom: 32px;
  }
  .ant-form-item .ant-form-item-explain-error {
    color: $mainColor;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border: 1px solid $secondaryBackground !important;
    // margin-bottom: 8px;
    box-shadow: none !important;
  }

  textarea {
    resize: none;
  }
  &__box {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__label {
    color: $primaryColor !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 18px;
    line-height: 18px;
  }
  &__textarea {
    ::placeholder {
      color: $borderPrimary !important;
      font-size: 14px !important;
      line-height: 18px;
    }
  }
}

// MainLabel
.main_label {
  color: #898989;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}

//MainToast
:where(.css-dev-only-do-not-override-1m62vyb)[class^="ant-message"]
  [class^="ant-message"],
:where(.css-dev-only-do-not-override-1m62vyb)[class*=" ant-message"]
  [class^="ant-message"],
:where(.css-dev-only-do-not-override-1m62vyb)[class^="ant-message"]
  [class*=" ant-message"],
:where(.css-dev-only-do-not-override-1m62vyb)[class*=" ant-message"]
  [class*=" ant-message"] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

//MainPopup
.main_popup_wrapper {
  position: fixed;
  inset: 0;
  background-color: rgb(#000000, 0.6);
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .main_popup_body {
    position: relative;
    text-align: center;
    // background-color: $primaryBackground;
    border-radius: 8px;
    animation: bum 0.1s ease forwards;
    transition: all 0.1s linear;
    width: auto;
    height: auto;
    &_close {
      position: absolute;
      top: 10px;
      right: 10px;
      // top: 6px;
      // right: 6px;
      // font-size: 14px;
      // color: $borderPrimary;
      // width: 16px;
      // height: 16px;
      cursor: pointer;
      // background-image: url("https://cross.namefragrance.vn/imgs/loyaltyIcon/loyalty_close-icon.png");
      // background-image: url("../public/assets/imgs/loyalty_close-icon.png");
      // background-repeat: no-repeat;
    }
    &_content {
      line-height: 14px;
    }

    @media screen and (max-width: 768px) {
      $padding: 16px;
      padding: $padding;
      &_close {
        top: calc($padding + 6px);
        right: calc($padding + 5px);
      }
    }
  }
}
.picture-frame-container {
  position: relative;
  width: 400px;
  height: 400px;
  img {
    position: absolute;
    inset: 0;
  }
  .background-image {
    z-index: 1;
  }
  .picture-frame {
    z-index: 2;
  }
  button {
    z-index: 3;
    position: relative;
  }
}

//MainSelect
.main_select {
  .ant-select-selector {
    border-radius: 0;
    border: none !important;
    border-bottom: 1px solid #898889 !important;
    background: $primaryBackground !important;
    width: 100%;
    height: 26px !important;
    padding: 0 !important;
    &:focus,
    &:focus-visible,
    &:focus-within {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .ant-select-selection-item {
    padding-inline-end: 0;
    color: $primaryColor;
    font-size: 14px;
  }
  .ant-select .ant-select-arrow {
    color: $primaryColor;
    inset-inline-end: 0;
  }
  .ant-select .ant-select-clear {
    inset-inline-end: 0;
  }
}

//MainSelectMultiple
.select_multiple_custom {
  .ant-select-selector {
    height: 100%;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: white !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: green;
  }
  .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: unset !important;
  }
  .custom-dropdown {
    padding-left: 16px;
    &-option {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
    }
    .option-label {
      font-size: 14px;
    }
    .option-checkbox {
      cursor: pointer;
      border-radius: 4px;
    }
    input[type="checkbox"]:checked {
      accent-color: #00d47b;
    }
    &::after {
      display: none !important;
    }
  }
}
.main_select_multiple {
  // max-width: 500px;
  min-height: 44px !important;
  margin-top: 8px;

  .ant-select-selection-overflow {
    gap: 10px;
    min-height: 18px !important;
    padding-right: 10px !important;
  }

  .ant-select-multiple .ant-select-selector {
    padding: 10px 20px 10px 16px;
    background: $primaryBackground !important;
    font-size: 14px !important;
    line-height: 18px !important;
    border-radius: 4px;
    border: 1px solid #d8d7d8 !important;
    min-height: 18px !important;
    min-height: 44px !important;
  }

  .ant-select-selection-placeholder {
    color: $borderPrimary !important;
    font-size: 14px !important;
    padding-left: 4px;
    .ant-select-selector::after {
      display: none !important;
    }
    .ant-select-selection-overflow {
      margin-right: 10px;
    }
    .ant-select-selection-overflow-item-suffix {
      display: none !important;
    }
  }
  .ant-select-multiple .ant-select-selector:after {
    line-height: 18px !important;
    height: 18px !important;
  }
  .ant-select-selection-search-input {
    height: 18px !important;
  }
  .ant-select-selection-search {
    display: none !important;
  }
  .ant-select-selection-overflow-item-suffix {
    display: none !important;
  }
  .ant-select .ant-select-arrow {
    color: $primaryColor !important;
  }
}

//CheckPasswordInput.tsx
.checkpassword_input_wrapper {
  border: 1px solid $borderPrimary;
  border-radius: 4px;
  position: relative;
  text-overflow: ellipsis;

  &_label {
    position: absolute;
    inset: 0;
    font-size: $fontSizeDefault;
    color: $thirdColor;
    transition: all 0.2s linear;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    min-width: 70%;
    z-index: 9;
  }
  input.main_input {
    padding-right: 34px !important;
  }
  input.main_input::placeholder {
    margin-left: -34px !important;
  }
  .main_input {
    margin: 5px 0;
    text-align: center;
    width: 100%;
    border: none;
    position: relative;
    z-index: 10;
    padding: 0;
    background-color: transparent;
    // &::placeholder {
    //   visibility: hidden;
    // }
    // &:not(:placeholder-shown) ~ .main_input_wrapper_label {
    //   top: -14px;
    //   font-size: 10px;
    // }
    // &:focus ~ .main_input_wrapper_label {
    //   top: -14px;
    //   font-size: 10px;
    // }
  }
}

//MainInputBorder.tsx
.main_input_border__input::placeholder {
  color: $borderPrimary !important;
}

.main_input_border {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__label {
    color: $primaryColor !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    height: 18px;
    line-height: 18px;
  }
  &__box {
    position: relative;
  }
  &__input {
    width: 100%;
    padding: 13px 14px;
    border-radius: 4px;
    border: 1px solid $secondaryBorder;
    background: $primaryBackground;
    height: 44px;
    line-height: 18px;
    ::placeholder {
      color: $borderPrimary !important;
      font-size: 14px !important;
      height: 18px;
      line-height: 18px;
    }
  }
  &__eye {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
  }
}
////////////////////////////////
//
#test {
  position: relative;
  canvas {
    position: absolute;
    inset: 0;
    // width: 180px;
    // height: 180px;
  }
  #bg {
    z-index: 2;
  }
  #frame {
    z-index: 1;
  }
  #bgResult {
    z-index: 2;
  }
  #frameResult {
    z-index: 1;
  }
  button {
    position: relative;
    z-index: 3;
  }
}

#imgResult {
  position: relative;
  canvas {
    position: absolute;
    inset: 0;
    // width: 180px;
    // height: 180px;
  }

  #bgResult {
    z-index: 2;
  }
  #frameResult {
    z-index: 1;
  }
  button {
    position: relative;
    z-index: 3;
  }
}

//MainCheckbox
.main_checkbox {
  &__label {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__input {
    margin: 0;
    cursor: pointer;
    border-color: #898889;
    opacity: 1;

    &:hover {
      opacity: 1;
      border-color: #898889;
    }
    &:checked {
      accent-color: green;
    }
  }
}
//MainTabs
.main_tabs {
  .ant-tabs .ant-tabs-tab {
    padding: 0 !important;
    margin: 0 0 11px 32px !important;
    line-height: 22px !important;
    &:first-child {
      margin-left: 0 !important;
    }
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #dc0719 !important;
    // font-weight: 700 !important;
  }
  .ant-tabs-ink-bar {
    background: #dc0719 !important;
  }
}

//MainSlider
.main-slider {
  .ant-slider-horizontal .ant-slider-rail {
    width: 100%;
    height: 1px !important;
    background-color: #d8d7d8 !important;
  }
  .ant-slider-horizontal .ant-slider-track,
  .ant-slider.ant-slider-disabled .ant-slider-track {
    height: 1px !important;
    background-color: #d72229 !important;
  }
  .ant-slider .ant-slider-handle::after,
  .ant-slider.ant-slider-disabled .ant-slider-handle::after {
    background-color: #d72229 !important;
    width: 8px !important;
    height: 8px !important;
    box-shadow: none !important;
    inset-block-start: 0 !important;
    inset-inline-start: 0 !important;
  }
  .ant-slider .ant-slider-handle::before {
    display: none !important;
  }
  .ant-slider .ant-slider-handle {
    width: 8px !important;
    height: 8px !important;
  }
  .ant-slider-horizontal .ant-slider-handle,
  .ant-slider-horizontal .ant-slider-handle:hover {
    inset-block-start: 0px !important;
  }
  .ant-slider .ant-slider-mark-text {
    width: 80px;
    color: #3a393a !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    margin-top: 10px;
  }
  .ant-slider .ant-slider-dot {
    background-color: #d8d7d8;
    border: 2px solid #d8d7d8;
    transition: all 0.3s;
  }
  .ant-slider-horizontal .ant-slider-dot {
    inset-block-start: -4px !important;
  }
  .ant-slider .ant-slider-dot-active {
    background-color: #d72229;
    border: 2px solid #d72229;
  }
}
