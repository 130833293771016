.term {
  details {
    &::-webkit-details-market {
      display: none;
    }
    summary {
      &::-webkit-details-market {
        display: none;
      }
    }
  }
}
